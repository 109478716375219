/* SharedDetails.css */

.share-details-container {
  width: 67em;
  /* max-width: 800px; */
  display: flex;
  /* flex-wrap: wrap; */
  padding: 20px;
  /* height: 150vh; */
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.share-details-form {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  justify-content: center;
  /* margin: auto; */
  width: 100%;
}

.share-details-row {
  display: flex;
  gap: 30px;
  /* flex-basis: 100%; */
  justify-content: center;
}

.share-details-column {
  flex: 1;
  width: 100%;
}

.share-details-form-group {
  margin-bottom: 20px;
  width: 100%;
}

.share-details-label {
  color: #333;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}
.share-details-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.share-btn-submit {
  background-color: #004f83;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 45px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .share-details-column {
    max-width: 100%;
  }
  .share-details-row {
    display: flex;
    gap: 1em;
    /* flex-basis: 100%; */
    justify-content: center;
    flex-direction: column;
  }
  .share-details-container {
    width: 100%;
    /* max-width: 800px; */
    display: flex;
    /* flex-wrap: wrap; */
    padding: 20px;
    /* height: 150vh; */
    justify-content: center;
    flex-direction: column;
    /* margin: auto; */
    padding-left: 26px;
  }
}

@media (max-width: 1024px) {
  .share-details-container {
    width: 100%;
    /* max-width: 800px; */
    display: flex;
    /* flex-wrap: wrap; */
    padding: 20px;
    /* height: 150vh; */
    justify-content: center;
    flex-direction: column;
    margin: auto;
  }
}
