/* StartEndDetails.css */

.start-end-details-container {
  padding: 5px;
}

.start-end-details-form {
  display: flex;
  flex-direction: column;
  width: 69em;
  justify-content: center;
  margin: auto;
}

.start-end-details-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.start-end-details-column {
  flex: 1;
  width: 100%; /* Minimum width for each column */
}

/* .start-end-details-form-group {
    margin-bottom: 20px;
  } */

.start-end-details-label {
  color: #333;
  font-weight: bold;
  margin-bottom: 5px;
}

.start-end-details-input {
  border: 1px solid #ccc;
  padding: 8px;
  flex: 1 1;
  width: 100%;
  box-sizing: border-box;
}

.start-end-details-button-row {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.start-end-details-button {
  background-color: #dc3545;
  color: #fff;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

.startenddetails-input {
  /* width: 200px; */
  /* height: 43px; */
  border-radius: 3px;
}
.rate-form-control-Startenddetails {
  width: 275px;
  height: 43px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.width-setfor-nght-stay-and-toll-park-inputs {
  width: 50%;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .start-end-details-column {
    flex: 1 0 100%; /* Full width for small screens */
  }
  .View-Corporate-Customer-Rate {
    text-align: center;
    font-size: 23px;
    padding: 1em 0;
    font-weight: 500;
    margin: 0;
  }
  .start-end-details-row {
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .start-end-details-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: auto;
  }
  .rate-form-control-Startenddetails {
    width: 100%;
    height: 43px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .start-end-details-container {
    padding: 20px;
    padding-left: 27px;
  }
  .width-setfor-nght-stay-and-toll-park-inputs {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .start-end-details-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    /* justify-content: center; */
  }

  .start-end-details-form {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    margin: auto;
  }
}
