.about-main-container {
  width: 72em;
  margin: auto;
}
.about-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10vh;
  height: 100vh;
}

.about-content {
  width: 40%;
  color: #49557e;
  animation: swipeRight 1s;
}
.about-content h1 {
  font-size: max(5vw, 25px);
}
.about-content p {
  font-size: 20px;
}
.about-img {
  width: 40%;
  animation: swipeLeft 1s;
}

.about-img img {
  width: 100%;
}
.about-content h1 span {
  color: #13b8a7;
}

.about-buttons {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}
.about-buttons button {
  background: transparent;
  font-size: 16px;
  background-color: #49557e;
  color: white;
  /* border: 1px solid #13B8A7; */
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.about-buttons .contact-btn {
  background-color: #13b8a7;
  color: white;
}

@media (max-width: 850px) {
}
@media (max-width: 768px) {
  .about-main-container {
    width: 100%;
    padding: 1em;
  }
  .about-buttons {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    justify-content: center;
  }
  .about-img {
    width: 100%;
    animation: swipeLeft 1s;
  }
}
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    width: 100%;
    gap: 60px;
    margin: auto;
    margin-bottom: 50px;
    padding-bottom: 50px;
    text-align: center;
    margin-top: 6em;
    height: 100%;
  }
  .about-content {
    width: 100%;
  }
  .about-content h1 {
    font-size: 40px;
    font-weight: 500;
  }
  .about-content p {
    font-size: 14px;
    padding: 2em 0;
  }

  .about-img img {
    width: 100%;
    margin: auto;
  }
  .about-buttons button {
    padding: 8px 16px;
    font-size: 11px;
  }
  /* .about-buttons button{
        padding: 6px 16px;
        
    } */
}

.extended-about-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  color: #49557e;
  padding-top: 40px;
}
.extended-about-us h1 {
}

.extended-about-us p {
  font-size: 18px;
}

@media (max-width: 1024px) {
  .extended-about-us {
    width: 100%;
    margin-left: 0;
  }
}

@keyframes swipeRight {
  from {
    margin-left: -100px;
  }
  to {
    margin-left: 0px;
  }
}
@keyframes swipeLeft {
  from {
    margin-right: -100px;
  }
  to {
    margin-right: 0px;
  }
}

@media (min-width: 1024px) {
  .about-main-container {
    width: 80%;
    margin: auto;
  }
}
