/* .sidebar {
  position: absolute;
  top: 6%;
  width: 100%;
  height: 100%;
  background-color: white;
  background-color: #01004c;
  color: #49557e;
  z-index: 10000;
  animation: swipe 0.3s ease;
} */
.sidebar {
  position: fixed;
  top: 5.8%;
  width: 100%;
  height: 100%;
  /* background-color: white;
  background-color: #01004c; */
  color: #49557e;
  z-index: 10000;
  animation: swipe 0.3s ease;
}

.sidebar-content {
  padding: 20px;
  /* padding-left: 10vw; */
}
.sidebar-content a {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  /* border: 1px solid #49557e; */
  border: 1px solid white;
  padding: 4px 7px;
  border-radius: 6px;
  text-decoration: none;
  /* color: #49557e; */
  color: white;
  height: 4em;
  align-items: center;
  justify-content: center;
}

.sidebar-content a:hover {
  /* background-color:#6acfc5; */
  /* color: black; */
}

@keyframes swipe {
  from {
    left: -100px;
  }
  to {
    left: 0px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    display: flex;
  }
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 7.5%;
    width: 100%;
    height: 100%;
    background-color: #49557e;
    /* color: #49557e; */
    z-index: 10000;
    animation: swipe 0.3s ease;
  }
  .sidebar-content {
    width: 100%;
    padding-top: 5em;
  }
}
