.section-for-nav-link--collapsible-hamberger {
  position: sticky;
  top: 0;
  height: 71px;
  padding: 0;
  width: 100%;
  z-index: 1;
  justify-content: space-around;
}
.logo-image-car-booking-main {
  width: 171px;
  height: 59px;
}

@media (max-width: 1024px) {
  .responsvie-side-bar-section {
    /* width: 26rem; */
  }
}

/* @media (max-width: 425px) and (min-width: 320px) {
  .responsvie-side-bar-section {
    width: 100%;
  }
  .section-for-nav-link--collapsible-hamberger {
    width: 100%;
  }
} */
