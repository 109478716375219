.custom-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.custom-card-body {
  padding: 0;
}

.custom-card-title {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.custom-card-subtitle {
  font-size: 1rem;
  margin-bottom: 4px;
}
.add-viewcus-btn {
  margin-bottom: 10px !important;
}
.add-viewcus-btn {
  color: white;
}
.View-Corporate-Customer-Rate {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 8px;
}
/* style={{
  fontSize: "2rem",
  fontWeight: "bold",
  marginBottom: "8px",
}} */

@media (max-width: 768px) {
  .search-box-and-individual-customer-btn {
    flex-direction: column;
    gap: 1em;
  }
  .customer-Add-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: rgb(200, 205, 122); */
    padding-left: 15px;
    /* margin-left: 300px; */
    /* height: 712px; */
  }

  .table-view {
    overflow-x: scroll;
  }
  .View-Corporate-Customer-Rate {
    text-align: center;
    font-size: 23px;
    padding: 1em 0;
    font-weight: 500;
    margin: 0;
  }
  .Indivisual-Customer-btn-cc--cc {
    width: 80%;
    padding: 1em 0;
    margin-right: 1em;
  }
  .Search-by-Corporate-Customer-Name {
    font-size: 10px;
    height: 4em;
  }
  .search-bar > input {
    text-align: center;
  }
  .search-input-and-btn-for-individual-customer-cc {
    flex-direction: column;
    gap: 0.8em;
  }
}

@media (max-width: 1024px) {
  .table-view {
    overflow-x: scroll;
  }
  .search-input-and-btn-for-individual-customer-cc {
    flex-direction: column;
    gap: 0.8em;
  }
}
