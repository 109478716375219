/* CustomerInquiry.css */

/* Reset styles */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: rgb(200, 205, 122);
}

/* Container for the entire page */
.customer-inquiry-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 8px;
  /* margin-left: 300px; */
}

/* Sidebar styles (adjust as needed) */
/* Add styles for your Sidebar component */

/* Main container styles */
.main-container {
  max-width: 960px;
  width: 100%;
  /* background-color: #fff; */
  /* border: 1px solid #ccc; */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
}

/* Form group styles */
.form-group {
  margin-bottom: 15px;
}

/* Form label styles */
.form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

/* Form input and select styles */
.form-control-cust-inq-input-corp-invoice-monthly-cc {
  width: 100%;
  height: 43px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}
.cust-inq-input {
  width: 235px;
  /* height: 40px; */
}
.form-control-date,
.form-control-time,
.form-control-number {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

/* Custom submit button styles */
.btn-submit {
  background-color: #004f83;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #0056b3;
}
.search-bar-view-customer-enquiry {
  padding: 1em;
}
/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .main-container {
    padding: 15px;
  }

  .form-control {
    padding: 8px;
  }

  .form-control-date,
  .form-control-time,
  .form-control-number {
    padding: 8px;
  }

  .btn-submit {
    padding: 8px 16px;
    width: 100%;
    margin: 1em 0;
  }
  .cust-inq-input {
    width: 100%;
    /* height: 40px; */
  }
  .search-bar-view-customer-enquiry {
    padding: 0px;
  }
  .search-bar-view-customer-enquiry > input {
    text-align: center;
  }
}
.days {
  color: rgb(12, 12, 12);
  position: absolute;
  margin-left: -50px;
  margin-top: 39px;
}

@media (max-width: 1024px) {
  .customer-inquiry-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    /* margin-left: 300px; */
    width: 80%;
    margin: auto;
  }
  .responsive-flex-column-required-1024-pxl {
    flex-wrap: wrap;
    justify-content: center;
  }
  .form-label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    font-size: 13px;
  }
}
