/* UpdateDuty.css */

/* Style for the container div */
.update-duty-container {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 69em;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  /* width: 100vw; */
  overflow-x: hidden;
}

/* Style for the form div */
.update-duty-form {
  display: flex;

  width: 100%;
  justify-content: center;
  margin: auto;
}

/* Style for each row in the form */
.form-row {
  display: flex;
  width: 100%;
  justify-content: space-between; /* Separate the form into two columns */
  margin-bottom: 15px;
}

/* Style for form labels */
.update-duty-form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

/* Style for form inputs and select */
.update-duty-form-control {
  /* width: 500px; Make all inputs and selects full width */
  padding: 8px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-bottom: 10px; /* Add some space between input fields */
}

/* Style for button container */
.button-container {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line on smaller screens */
  /* justify-content: space-between; */
  margin-top: 20px;
  gap: 30px;
}
.width-set-for-the-div-section-container-label-and-input-add-duty-slip {
  width: 50%;
}
.gap-for-div-section-container-label-and-input-add-duty-slip {
  gap: 2em;
}
.container-btns-view-update-duty-slip {
  display: flex;

  gap: 1em;
}
.View-update-duty-Trip-Details-search-input-box {
  width: 50%;
  margin: auto;
}
.width-set-for-view-update-duty-input-div-section {
  width: 69em;
  margin: auto;
}
@media (max-width: 768px) {
  .update-duty-container {
    display: flex;
    margin: auto;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    padding-left: 27px;
  }
  /* Reset width for buttons to full width on smaller screens */
  .btn-save,
  .btn-print {
    width: 100%;
    margin-bottom: 10px; /* Add some space between buttons on smaller screens */
  }
  .update-duty-form-control {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .width-set-for-the-div-section-container-label-and-input-add-duty-slip {
    width: 100%;
  }
  .gap-for-div-section-container-label-and-input-add-duty-slip {
    gap: 0;
  }
  .container-btns-view-update-duty-slip {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
  .View-Driver-Trip-Details-search-input-box {
    padding: 1em 1em;
  }
  .width-set-for-view-update-duty-input-div-section {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .update-duty-container {
    display: flex;
    margin: auto;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    /* width: 100vw; */
    overflow-x: hidden;
  }
}
