/* .container-cust-invoice{
     
    height: auto;
    
} */
.container-customer-invoice {
  padding: 20px;
  width: 69em;
  margin: auto;
}

.cibtn {
  background-color: rgb(247, 126, 126);
  color: white;
}

.form-customer-invoice {
  display: flex;
  gap: 5px;
}
.heading {
  display: flex;
}

.to-design {
  color: rgb(12, 12, 12);
  /* background-color: white; */
  margin-top: 15px;
}
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-table th {
  background-color: #f2f2f2;
}
.form-control-customer-invoice {
  display: block;
  width: 400px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
}

.form-control-cust-inq-input-corp-invoice-monthly {
  width: 100%;
  height: 43px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

@media (max-width: 768px) {
  .container-customer-invoice {
    padding: 6px;
    width: 100%;
    margin: auto;
    padding-left: 14px;
  }
  .main-input-container-corporat-invoice-page {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .container-customer-invoice {
    padding: 20px;
    width: 100%;
    margin: auto;
  }
  .main-input-container-corporat-invoice-page {
    flex-direction: column;
    width: 80%;
    margin: auto;
  }
  .container-for-corporate-customer-monthly-invoice-page-responsive-cc {
    flex-direction: column;
  }
}
