/* ViewVendor.css */

.custom-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.custom-card-body {
  padding: 0;
}

.custom-card-title {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.custom-card-subtitle {
  font-size: 1rem;
  margin-bottom: 4px;
}
.vendorbutton {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}

@media (max-width: 768px) {
  .responsive-over-flow-x-scroll-table {
    overflow-y: scroll;
  }
}
@media (max-width: 1024px) {
  .responsive-over-flow-x-scroll-table {
    overflow-y: scroll;
  }
}
