.trip-details-input-trip-details-report-page {
  max-width: 200px; /* Adjust the width as needed */
  flex: 1; /* Take up equal width */
  padding: 8px;

  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
.trip-counts-container {
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
}

.trip-counts {
  /* border: 1px solid #ccc; Optional: Add border for each cell */
  padding: 10px; /* Optional: Add padding for each cell */
  text-align: center;
}
.form-groupx {
  margin: auto;
  display: flex;

  flex-direction: column;

  padding: 1em 0;
}
.div-contains-labl-dropdown-and-the-export-to-excel-button-monthly-customer-report {
  text-align: center;
  margin-bottom: 20px;

  display: flex;
}
@media (max-width: 768px) {
  .form-groupx {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 3em 0;
  }
  .trip-details-input-trip-details-report-page {
    max-width: 100%;
    flex: 1 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    height: 40px;
  }
  .btn-Export-to-Excel-trip-details {
  }
  .section-for-input-and-btn-for-trip-details-page {
    flex-direction: column;
  }
  .trip-counts-container {
    display: flex;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    flex-direction: column;
  }
  .trip-details-responsive-table-overflow-y-scrolling-responsive {
    overflow-y: scroll;
  }
  .table-for-monthly-customer-report-page {
    overflow-x: scroll;
  }
  .table-for-monthly-customer-report-page-width-set-cc {
    width: 74em;
  }
  .div-contains-labl-dropdown-and-the-export-to-excel-button-monthly-customer-report {
    text-align: center;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 1em;
    display: flex;
  }
}

@media (max-width: 1024px) {
  .table-for-monthly-customer-report-page {
    overflow-x: scroll;
  }
  .div-contains-labl-dropdown-and-the-export-to-excel-button-monthly-customer-report {
    text-align: center;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 1em;
    display: flex;
  }
  .trip-counts-container {
    display: flex;
    /* justify-content: space-between; */
    padding: 1em 0;
    gap: 1em;
    flex-wrap: wrap;
    justify-content: center;
  }
  .trip-details-responsive-table-overflow-y-scrolling-responsive {
    overflow-y: scroll;
  }
}
