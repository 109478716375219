/* SharedDetails.css */

/* Form styles */
.share-details-form {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

/* Row and column styles */
.share-details-row {
  display: flex;
  margin-bottom: 15px;
}

.share-details-column {
  flex: 1;
  margin-right: 15px;
}

/* Form group styles */
.share-details-form-group {
  margin-bottom: 15px;
}

/* Label styles */
.share-details-label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

/* Input styles */
.share-details-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button styles */
.share-details-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.share-details-button:hover {
  background-color: #45a049;
}

/* Error message styles */
.text-red-500 {
  color: #e53e3e;
  margin-top: 5px;
  font-size: 14px;
}

/* Calendar icon styles */
.date-picker-container {
  display: flex;
  flex-direction: column;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.calendar-icon {
  font-size: 18px;
  color: #555;
  cursor: pointer;
  margin-right: 10px; /* Adjust the margin to provide space between icon and input */
}

.share-details-input {
  padding: 8px; /* Adjust padding as needed */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .share-details-main-container {
    overflow-x: scroll;
  }
  .Search-by-date-or-driver-name {
    text-align: center;
  }
  .btn-view-share-details-flex-wrap-for-page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
