/* ViewVendor.css */

.custom-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.custom-card-body {
  padding: 0;
}

.custom-card-title {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.custom-card-subtitle {
  font-size: 1rem;
  margin-bottom: 4px;
}
.vendorbutton {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}

.view-allocate-trip-details-container {
  width: 67em;
  /* max-width: 800px; */

  /* flex-wrap: wrap; */
  padding: 20px;
  /* height: 150vh; */
  justify-content: center;
  /* flex-direction: column; */
  margin: auto;
}
.share-details-main-container {
  display: grid;
  justify-content: center;
}
@media (max-width: 768px) {
  .view-allocate-trip-details-container {
    width: 100%;
    /* max-width: 800px; */
    display: flex;
    /* flex-wrap: wrap; */
    padding: 20px;
    flex-direction: column;
    /* height: 150vh; */
    justify-content: center;
    /* flex-direction: column; */
    margin: auto;
  }
  .share-details-main-container {
    display: block;
  }
}
@media (max-width: 1024px) {
  .view-allocate-trip-details-container {
    width: 100%;
    /* max-width: 800px; */
    display: flex;
    /* flex-wrap: wrap; */
    padding: 20px;
    flex-direction: column;
    /* height: 150vh; */
    justify-content: center;
    /* flex-direction: column; */
    margin: auto;
  }
  .share-details-main-container {
    display: block;
    overflow-x: scroll;
  }
}
