.card-1 {
  width: 90%;
  margin: auto;
}
.form-body {
  height: auto;
  margin-left: 180px;
}
.container-fluid {
}
.rate-form-control-payment {
  width: 250px;
  height: 43px;
}
.customer-btn-submit {
  background-color: #004f83;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 45px;
}

.customer-btn-submit:hover {
  background-color: #0056b3;
}
#btn1 {
  margin-left: 26%;
}
#btn2 {
  margin-left: 2%;
}
#btn3 {
  margin-left: 2%;
}
.main-container-vendor-payment-section {
  width: 63em;
  margin: auto;
}
.btn-flx-col-for-table-data-download-invoice-view-vendor-payment-section {
  display: flex;
  height: 5em;
  align-items: center;
  gap: 12px;
}
.main-container-div-for-vender-payment-section {
  display: flex;
  gap: 1em;
}
@media (max-width: 768px) {
  .main-container-vendor-payment-section {
    width: 100%;
    margin: auto;
    padding: 1em;
    padding-left: 23px;
  }
  .view-vendor-payment-table-overflow-y-cc {
    overflow-y: scroll;
  }
  .btn-flx-col-for-table-data-download-invoice-view-vendor-payment-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    height: auto;
  }
}
@media (max-width: 1024px) {
  .main-container-div-for-vender-payment-section {
    display: flex;
    gap: 1em;
    flex-direction: column;
  }
  .main-container-vendor-payment-section {
    width: 80%;
    margin: auto;
  }
  .btn-flx-col-for-table-data-download-invoice-view-vendor-payment-section {
    display: grid;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 1em;
  }
  .view-vendor-payment-table-overflow-y-cc {
    overflow-y: scroll;
  }
}
