/* *{
    margin: 0;
    padding: 0;
    
} */
.contact-us {
  /* padding-top: 10vh; */
  padding-bottom: 50px;
  width: 72em;
  margin: auto;
  height: 100vh;
}
.contact-us-content {
  display: flex;
  justify-content: space-around;
  gap: 50px;
  margin-top: 6em;
  margin-bottom: 50px;
  animation: swipeBtm 1s;
  /* flex-direction: column; */
}
.contact-us h4 {
  font-size: 50px;
  color: #49557e;
  letter-spacing: -3px;
  line-height: 55px;
  word-spacing: 4px;
  font-family: system-ui;
  /* margin-bottom: 50px; */
  padding-top: 3em;
}

.contact-us hr {
  width: 100%;
  height: 2px;
  margin: 20px 0;
  background-color: gray;
  border: none;
}

.contact-us h6 {
  font-size: 20px;
  color: #1c1c1c;
  font-weight: 600;
  margin-bottom: 10px;
}
.contact-us h5 {
  font-size: 20px;
  color: #1c1c1c;
  font-weight: 600;
  margin-bottom: 12px;
}
.contact-us p {
  font-size: 18px;
  color: #828282;
  margin-bottom: 4px;
}
.contact-us .email {
  color: #13b8a7;
}
.blog-container-heading {
  margin-top: 6em;
}
@media (max-width: 768px) {
  .contact-us-content {
    flex-direction: column;
    text-align: center;
    gap: 26px;
    margin-top: 6em;
  }
  .contact-us h4 {
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
  }
  .contact-us {
    width: 100%;
    padding: 1em;
  }
  .blog-container-heading {
    margin-top: 6em;
  }
  .contact-us h5 {
    font-size: 17px;
    color: #1c1c1c;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .contact-us h6 {
    font-size: 15px;
    color: #1c1c1c;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .contact-us p {
    font-size: 14px;
    color: #828282;
    margin-bottom: 4px;
  }
}

@keyframes swipeRight {
  from {
    margin-left: -100px;
  }
  to {
    margin-left: 0px;
  }
}
@keyframes swipeLeft {
  from {
    margin-right: -100px;
  }
  to {
    margin-right: 0px;
  }
}

.contact-us-left {
  animation: swipeRight 1s ease;
}
.contact-us-right {
  animation: swipeLeft 1s ease;
}

@media (min-width: 1024px) {
  .contact-us {
    /* padding-top: 10vh; */
    padding-bottom: 50px;
    width: 80%;
    margin: auto;
    /* height: 79vh; */
  }
}
