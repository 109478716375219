.footer {
  background-color: #49557e;
  color: white;
  /* background-color: #c6cb77; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 8vw;
  padding-top: 80px;
  margin-top: 50px;
}

.footer-content {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 80px;
}
.footer-content-left p {
  margin-bottom: 20px;
}
.footer-social-icons {
  padding: 3em 0;
}
.footer-content-left,
.footer-content-right,
.footer-content-center {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
}

.footer-content-left,
.footer-content-right li,
.footer-content-center a {
  list-style: none;
  margin-bottom: 5px;
  /* cursor: pointer; */
  text-decoration: none;
  /* color: #49557e; */
}

.footer-content-center ul {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0;
}
.footer-content-right ul {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0;
}

.footer-content-right h2,
.footer-content-center h2 {
  /* color: #49557e; */
  text-decoration: underline;
  font-size: large;
}

.footer-social-icons {
  display: flex;
  gap: 15px;
  cursor: pointer;
}

.footer hr {
  width: 100%;
  height: 2px;
  margin: 20px 0;
  background-color: gray;
  border: none;
}

.footer .logo {
  width: 150px;
}

@media (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px 8vw;
    padding-top: 40px;
    margin-top: 100px;
  }
  .footer-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 15px;
    text-align: center;
  }
  .footer-content-right h2,
  .footer-content-center h2 {
    font-size: 18px;
  }
  .footer-social-icons {
    display: flex;
    gap: 15px;
    cursor: pointer;
    justify-content: center;
  }
  .footer-copyright {
    text-align: center;
  }
  .footer-social-logo-section {
    display: flex;
    justify-content: center;
  }
  .footer-content-left,
  .footer-content-right,
  .footer-content-center {
    display: flex;
    flex-direction: column;
    /* align-items: start; */
    gap: 20px;
    align-items: center;
  }
}
