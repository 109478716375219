/* CustomerInquiry.css */

/* Reset styles */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: rgb(200, 205, 122);
}

/* Container for the entire page */
.add-trip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  /* margin-left: 300px; */
  width: 69em;
  margin: auto;
  /* margin-left: 300px; */
}

/* Sidebar styles (adjust as needed) */
/* Add styles for your Sidebar component */

/* Main container styles */
.trip-main-container {
  /* max-width: 960px; */
  width: 71%;
  /* background-color: #fff; */
  /* border: 1px solid #ccc; */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

/* Form group styles */
.trip-form-group {
  margin-bottom: 15px;
}

/* Form label styles */
.trip-form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

/* Form input and select styles */
.form-control-add-trip-input {
  width: 100%;
  height: 42px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}
.add-trip-input {
  width: 234px;
}
.form-control-add-trip-input1 {
  width: 230px;
  height: 42px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.form-control-date,
.form-control-time,
.form-control-number {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

/* Custom submit button styles */
.trip-btn-submit {
  background-color: #004f83;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.trip-btn-submit:hover {
  background-color: #0056b3;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .main-container {
    padding: 15px;
  }

  .trip-form-control {
    padding: 8px;
  }

  .form-control-date,
  .form-control-time,
  .form-control-number {
    padding: 8px;
  }

  .trip-btn-submit {
    padding: 8px 16px;
    width: 100%;
  }
  .form-control-add-trip-input {
    width: 100%;
    height: 42px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    /* font-size: 12px; */
  }
  .pick-up-location-date-flex-section {
    flex-direction: column;
    padding-top: 1em;
  }
  .add-trip-input {
    width: 100%;
  }
  .sjkdbvkdbbjsbkdjb {
    width: 50%;
    font-size: 15px;
  }
  .rate-form-container-vender-rate-cc {
    width: 100%;
  }
  .modal-main-container-section-z-index {
    z-index: 9999;
  }
  .main-div-for-modal-container-for-all-inputs-cc {
    width: 100%;
    margin: 1em;
  }
  .add-trip-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    /* margin-left: 300px; */
    width: 100%;
    margin: auto;
    /* margin-left: 300px; */
  }
  .trip-main-container {
    /* max-width: 960px; */
    width: 100%;
    /* background-color: #fff; */
    /* border: 1px solid #ccc; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  .view-trip-table-container-div-cc {
    overflow-x: scroll;
  }
}

@media (max-width: 1024px) {
  .add-trip-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    /* margin-left: 300px; */
    width: 80%;
    margin: auto;
    /* margin-left: 300px; */
  }
  .pick-up-location-date-flex-section {
    flex-wrap: wrap;
    justify-content: center;
  }
  .view-trip-table-container-div-cc {
    overflow-x: scroll;
  }
}
