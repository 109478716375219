.grid-container {
  display: grid;
  grid-template-columns: 1fr auto; /* Divide into two columns, first column takes remaining space, second column auto size */
  align-items: center; /* Vertically center items */
  gap: 20px; /* Gap between grid items */
}

.filters {
  display: flex;
  justify-content: center;
  gap: 1em;
  grid-column: 1 / span 1; /* Span one column */
}

.export-button {
  grid-column: 2 / span 1; /* Span one column */
}

.filter-input {
  display: flex;
  position: relative;
  gap: 0.5em;
  justify-content: center;
  align-items: center;
}

.filter-input input[type="text"] {
  padding-right: 30px;
  width: 296px;
  height: 40px;
  box-sizing: border-box;
  text-align: center;
}

.filter-dropdown {
  position: relative;
  display: inline-block;
}

.filter-dropdown select {
  width: 200px; /* Adjust the width as needed */
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
}
.container-vendor-invoice-main-cc {
  /* margin-left: 300px; Leave 300px from the left side */
  padding: 20px;
  margin: auto;
  width: 69em;
}

.filter-dropdown::after {
  content: "\f078"; /* FontAwesome chevron icon */
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}
.filterss-and-filter-dropdown-with-export-btn0-vendor-report-page {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 1em;
}
@media (max-width: 768px) {
  .filters {
    display: flex;
    justify-content: center;
    gap: 1em;
    grid-column: 1 / span 1;
    flex-direction: column;
  }

  .filter-dropdown select {
    /* width: 100%; */
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
  }
  .grid-container {
    display: flex;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }
  .fa-filter {
    display: none;
  }
  .filter-input input[type="text"] {
    padding-right: 30px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    text-align: center;
  }
  .responsive-table-vendor-report--cc-overflow {
    overflow-x: scroll;
  }
  .container-vendor-invoice-main-cc {
    /* margin-left: 300px; Leave 300px from the left side */
    padding: 20px;
    width: 100%;
  }

  .filterss-and-filter-dropdown-with-export-btn0-vendor-report-page {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 1em;
  }
}

@media (max-width: 1024px) {
  .container-vendor-invoice-main-cc {
    /* margin-left: 300px; Leave 300px from the left side */
    padding: 20px;
    width: 100%;
  }
  .filterss-and-filter-dropdown-with-export-btn0-vendor-report-page {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 1em;
    align-items: center;
  }
  .filters {
    display: flex;
    justify-content: center;
    gap: 1em;
    grid-column: 1 / span 1;
    flex-direction: column;
    align-items: center;
  }
  .responsive-table-vendor-report--cc-overflow {
    overflow-x: scroll;
  }
}
