/* TripDetailsPage.css */

/* Container for the entire page */
.trip-details-container {
}

/* Styling for Trip Details Section */
.trip-details-section {
  flex: 1; /* Take up all available space */
  width: 100%; /* Full width on small screens */
  /* margin-bottom: 20px; Add space between sections */
}

/* Styling for Driver Details Section */
.driver-details-section {
  flex: 1; /* Take up all available space */
  width: 100%; /* Full width on small screens */
}

/* Styling for Headings */
.trip-details-heading,
.driver-details-heading {
  color: #333;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

/* Styling for Forms */
.trip-details-form,
.driver-details-form {
  display: flex;
  flex-wrap: wrap; /* Allow form fields to wrap to the next line */
  width: 100%;
}

/* Styling for Form Labels */
.trip-details-label,
.driver-details-label {
  flex: 1; /* Take up equal width */
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

/* Styling for Form Inputs */
.trip-details-input,
.driver-details-input {
  flex: 1; /* Take up equal width */
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Styling for Buttons */
.custom-button-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.custom-btn {
  /* padding: 10px 20px; */
  margin: 0 10px;
  width: 130px;
  height: 50px;
  background-color: red;
  cursor: pointer;
}

.custom-print-btn {
  background-color: #c73636;
  color: white;
}

.custom-allocate-btn {
  background-color: #004f83;
  color: white;
}

.custom-allocate-btn:hover {
  background-color: #0056b3;
}
.custom-generate-btn {
  background-color: #0d6efd;
  color: white;
}
.required-asterisk {
  color: red; /* or any other color */
  margin-left: 4px; /* adjust the margin as needed */
}
.allocate-trip-column-sections-margin-set-auto {
  border-top: 3px dashed black;
  width: 69em;
  display: flex;
  justify-content: center;
  margin: auto;
}
.aaaaaa {
  margin-top: -22px;
  color: black;
  font-size: 35px;
  margin-bottom: 28px;
  margin-left: 343px;
}
@media (max-width: 768px) {
  .trip-details-container {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: space-between;
    padding: 20px;
    margin-left: 10px;
    /* margin-top: 50px; */
  }
  .allocate-trip-column-sections-margin-set-auto {
    border-top: 3px dashed black;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
  }
  /* Styling for Buttons */
  .custom-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 1em 0;
  }
}

@media (max-width: 1024px) {
  .allocate-trip-column-sections-margin-set-auto {
    border-top: 3px dashed black;
    width: 80%;
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
  }
}
