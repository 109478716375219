/* CustomerInquiry.css */

/* Reset styles */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: rgb(200, 205, 122);
}

/* Container for the entire page */
.vendor-Add-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  margin: auto;
  width: 69em;
}

/* Sidebar styles (adjust as needed) */
/* Add styles for your Sidebar component */

/* Main container styles */
.vendor-main-container {
  /* max-width: 960px; */
  width: 100%;
  /* height: 100vh; */
  /* background-color: #fff; */
  /* border: 1px solid #ccc; */
  padding: 20px;
  border-radius: 8px;
}

/* Form group styles */
.vendor-form-group {
  margin-bottom: 15px;
}

/* Form label styles */
.vendor-form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

/* Form input and select styles */
.form-control-ven-add-input {
  width: 80%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}
.ven-add-input {
  width: 275px;
}

/* Custom submit button styles */
.vendor-btn-submit {
  background-color: #004f83;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.vendor-btn-submit:hover {
  background-color: #0056b3;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .main-container {
    padding: 15px;
  }

  .form-control-ven-add-input {
    padding: 8px;
  }

  .vendor-btn-submit {
    padding: 8px 16px;
  }
  .vendor-Add-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    margin: auto;
    width: 100%;
  }
  .vendor-form-container {
    padding: 0;
  }
  .form-control-ven-add-input {
    width: 100%;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
}

@media (max-width: 1024px) {
  .vendor-Add-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    margin: auto;
    width: 100%;
  }
}
