/* .container-cust-invoice{
     
    height: auto;
    
} */
.container-vendor-invoice-monthly {
  /* margin-left: 300px; Leave 300px from the left side */
  padding: 20px;
  width: 50em;
  margin: auto;
  height: 100vh;
}
button-vendor {
  color: red;
  margin-top: 10px;
}

.form-vendor-invoice-monthly {
  display: flex;
  gap: 5px;
}
.heading {
  display: flex;
}

.to-design {
  color: rgb(12, 12, 12);
  /* background-color: white; */
  margin-top: 15px;
}
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #020202;
  padding: 8px;
  text-align: left;
  background-color: #fff;
}

.invoice-table th {
  background-color: #f2f2f2;
}
.form-control-vendor-invoice-monthly {
  display: block;
  width: 400px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
}

.form-vendor-invoice {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the gap as needed */
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adjust the gap between the inputs as needed */
}

.form-group {
  flex: 1; /* Allows the elements to grow equally */
}

.form-label {
  display: block;
  margin-bottom: 5px; /* Adjust the spacing between the label and input */
}

.form-control-cust-inq-input {
  width: 80%; /* Make the input take the full width of its parent */
}

@media (max-width: 768px) {
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-direction: column;
  }
  .container-vendor-invoice-monthly {
    /* margin-left: 300px; Leave 300px from the left side */
    padding: 20px;
    width: 100%;
    margin: auto;
    height: 100vh;
  }
}

@media (max-width: 1024px) {
  .container-vendor-invoice-monthly {
    padding: 20px;
    width: 80%;
    margin: auto;
    height: 100vh;
  }
  .form-label {
    display: block;
    margin-bottom: 5px; /* Adjust the spacing between the label and input */
    font-size: 13px;
  }
}
