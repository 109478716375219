/* CustomerInquiry.css */

/* Reset styles */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: rgb(200, 205, 122);
  margin-left: px;
}

/* Container for the entire page */
.customer-Add-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  margin: auto;
  width: 69em;
}

/* Sidebar styles (adjust as needed) */
/* Add styles for your Sidebar component */

/* Main container styles */
.addcustomer-main-container {
  width: 100%;
  height: 95vh;
  padding: 20px;
  border-radius: 8px;
  /* position: relative; */
}

/* Form group styles */
.customer-form-group {
  margin-bottom: 15px;
}

/* Form label styles */
.customer-form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.form-control-cust-add-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  height: 40px;
}
.width-set-for-form-groupt-cc--dd {
  width: 80%;
  margin: auto;
}
.cust-add-input {
  width: 275px;
}

/* Custom submit button styles */
.customer-btn-submit {
  background-color: #004f83;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 45px;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 8em;
}

.customer-btn-submit:hover {
  background-color: #0056b3;
}
.required-asterisk {
  color: red; /* or any other color */
  margin-left: 4px; /* adjust the margin as needed */
}
/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .main-container {
    padding: 15px;
  }

  .form-control-cust-add-input {
    padding: 8px;
    width: 100%;
  }
  .form-control-cust-add-input-cc {
    padding: 8px;
    width: 77%;
    font-size: 15px;
  }

  .customer-btn-submit {
    padding: 8px 16px;
    width: 100%;
  }

  .customer-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin: auto;
    width: 100%;
  }
  #jbdksbhdbhksvhj {
    font-size: 10px;
  }
  .customer-main-container {
    width: 100%;
    height: auto;
  }
  .customer-main-container {
    overflow-x: scroll;
  }
  .addcustomer-main-container {
    /* max-width: 960px; */
    width: 100%;
    height: 95vh;
    border-radius: 8px;
    position: relative;
    margin-top: 1rem;
    padding: 0px;
  }
  .width-set-for-form-groupt-cc--dd {
    width: 100%;
  }
  .customer-Add-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    margin: auto;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .customer-Add-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    margin: auto;
    width: 100%;
  }
}
