.close-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem; /* Adjust as needed */
  color: #333; /* Adjust color */
}

.close-icon:focus {
  outline: none;
}

@media (max-width: 768px) {
  .Search-by-date-or-driver-name {
    text-align: center;
    padding: 0;
  }
  .modal-main-container-section-z-index {
    z-index: 9999;
  }
  .main-div-for-modal-container-for-all-inputs-cc {
    width: 100%;
    margin: 1em;
  }
  .View-Driver-Trip-Details-search-input-box {
    width: 100%;
    margin: auto;
    padding: 0 1em;
  }
  .btn-for-view-drive-details-flex {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
}
