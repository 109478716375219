@media (max-width: 768px) {
  .add-duty-type-and-add-rate-inputs {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .width-set-for-view-master-page-contains-table {
    width: 57em;
  }
  .view-mster-btns-for-edit-and-delete {
    flex-direction: column;
    display: flex;
  }
}
.add-duty-type-and-add-rate-inputs {
  display: flex;
  gap: 1em;
}

.width-set-for-view-master-page-contains-table {
  width: 100%;
}
