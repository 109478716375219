.landing-page-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  margin: auto;
}
.width-set-for-landing-page-container {
  width: 72em;
  margin: auto;
}
.landing-content {
  width: 40%;
  color: #49557e;
  animation: swipeRight 1s;
}
.landing-content h1 {
  font-size: 44px;
  color: #49557e;
  letter-spacing: -3px;
  line-height: 55px;
  word-spacing: 4px;
  font-family: system-ui;
  margin-bottom: 20px;
}
.landing-content p {
  font-size: 20px;
  color: #49557e;
  /* letter-spacing: px; */
  /* line-height: 55px; */
  word-spacing: 4px;
  font-family: system-ui;
}
.landing-img {
  width: 40%;
  animation: swipeLeft 1s;
}

.landing-img img {
  width: 100%;
}

@media (max-width: 768px) {
  .landing-page-container {
    flex-direction: column;
    width: 100%;
    gap: 20px;
    margin: auto;
    margin-bottom: 100px;
    height: 100%;
    padding: 1em;
    text-align: center;
  }
  .width-set-for-landing-page-container {
    width: 100%;
  }
  .landing-content {
    width: 95%;
    margin-top: 6em;
  }
  .landing-content h1 {
    font-size: 20px;
    font-weight: 500;
  }
  .landing-content p {
    font-size: 14px;
  }

  .landing-img {
    width: 80%;
  }
}

@keyframes swipeRight {
  from {
    margin-left: -100px;
  }
  to {
    margin-left: 0px;
  }
}
@keyframes swipeLeft {
  from {
    margin-right: -100px;
  }
  to {
    margin-right: 0px;
  }
}

@media (min-width: 1024px) {
  .width-set-for-landing-page-container {
    width: 80%;
    margin: auto;
  }
}
