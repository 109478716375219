.dashboard {
  margin-top: 100px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .overflow-scroll-fr-home-table-bar-chart {
    overflow-x: scroll;
  }
}
