/* CustomerInquiry.css */

/* Reset styles */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: rgb(200, 205, 122);
  margin-left: px;
}

/* Container for the entire page */
.driver-Add-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 8px;
  /* margin-left: 300px; */
}

/* Sidebar styles (adjust as needed) */
/* Add styles for your Sidebar component */

/* Main container styles */
.driver-main-container {
  max-width: 960px;
  width: 100%;
  /* height: 100vh; */
  /* background-color: #fff; */
  /* border: 1px solid #ccc; */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  margin-bottom: 20px;
}

/* Form group styles */
.driver-form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 80%;
}

/* Form label styles */
.driver-form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

/* Form input and select styles */
/* .form-control-cust-add-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  } */

.form-control-dri-add-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  height: 40px;
}

.dri-add-input {
  width: 275px;
}

/* Custom submit button styles */
.driver-btn-submit {
  background-color: #004f83;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: auto;
  width: 7em;
  margin-bottom: 45px;
}

.driver-btn-submit:hover {
  background-color: #0056b3;
}
.driver-form-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .main-container {
    padding: 15px;
  }

  .form-control-dri-add-input {
    padding: 8px;
    width: 100%;
  }

  .dri-btn-submit {
    padding: 8px 16px;
  }
  .driver-form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 100%;
  }
  .driver-main-container {
    max-width: 960px;
    width: 100%;
    height: 100vh;
    /* background-color: #fff; */
    /* border: 1px solid #ccc; */
    padding: 10px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    margin-bottom: 20px;
  }
}
/* .customer-main-container{
    width: 55%;
    height: auto;
    margin-left: -150px;
  } */

@media (max-width: 1024px) {
  .driver-Add-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    /* margin-left: 300px; */
    width: 80%;
    margin: auto;
  }
}
